
import * as React from "react"
import HeardTopWrapper from "../components/header-wrapper"
import Layout from "../components/layout/index"
import PageContainer from "../components/page-container"
import { Link } from "gatsby"
import { Button} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import{graphql} from "gatsby"
const NotFoundPage = () => {
  const {t} = useTranslation();
  return(
    <>
      <HeardTopWrapper title="404 NOT FOUND"/>
      <Layout>
          <Helmet>
            <title>My Kitchen Inspector｜404 NOT FOUND</title>
          </Helmet>
        <PageContainer>
          <div className="text-center not-found-page">
            <p>The page you requested does not exist or has moved.</p>
            
            <p>Please send an email to the <a href={`mailto:${process.env.EMAIL_ADDRESS}`}>administrator</a>, or </p> 

            <Link to="/"><Button className="addButton">Back to home</Button></Link>
          </div>
        </PageContainer>
      </Layout>
    </>
  
  )
}

export default NotFoundPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;